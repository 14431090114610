import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { player } from '../players/playersSlice';

export type ScoreState = {
  playerscores: playerScore[];
}
export type playerScore = {
  id: number;
  score: number;
  stats: stat[]
  yasatStreak: number;
}
export type stat = {
  name: string
}

const initialState: ScoreState = {
  playerscores: [],
};


export const scoreSlice = createSlice({
  name: 'scores',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStartScores: (state, action: PayloadAction<player[]>) => {
      const players = action.payload;
      // for each player add a score to the playerscores array
      players.forEach(player => {
        state.playerscores.push({id: player.id, score: 0, stats: [], yasatStreak: 0});
      });
    },
    
    // Use the PayloadAction type to declare the contents of `action.payload`
    addScores: (state, action: PayloadAction<playerScore[]>) => {
      state.playerscores = action.payload;     
    },

    resetScores() {
      return initialState;
    },

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = 'idle';
  //       state.value += action.payload;
  //     })
  //     .addCase(incrementAsync.rejected, (state) => {
  //       state.status = 'failed';
  //     });
  // },
});

export const { addScores, setStartScores, resetScores } = scoreSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectScores = (state: RootState) => state.scores.present.playerscores;
export const selectScoreState = (state: RootState) => state.scores
// export const scoreHistory = (state: RootState) => state.scores.past;



export default scoreSlice.reducer;
